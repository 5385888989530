import React, { useState, useEffect } from "react";
import AnchorLink from "react-anchor-link-smooth-scroll";
import { useMedia } from "use-media";

import Layout from "../../../components/chat-channel/Layout";
import Container from "../../../components/chat-channel/Container";
import TitleAndMetaTags from "../../../components/common/TitleAndHeader";
import { RequestForm } from "../../../components/form";
import { VideoBlog } from "../../../components/video";
import {
  Accordion,
  AccordionItem,
  AccordionItemHeading,
  AccordionItemButton,
  AccordionItemPanel,
} from "react-accessible-accordion";
import "react-accessible-accordion/dist/fancy-example.css";
import { GoToPopup } from "../../homepage";
import {
  cta_1,
  cta_1_mob,
  cta_2,
  cta_2_mob,
  ExistingBlogCta,
} from "./4-reasons-to-use-ms-teams-as-a-digital-helpdesk";
import { redirectToWithUtm } from "../../../components/Header";
const VidImg = require("../../../assets/img/integration/bamboohr/video_img.png");

const topImage = require("../../../assets/images/market_ui/goto_testimonial_cta.png");
const image1 = require("../../../assets/images/freshservice_blog/1_1.png");
const image2 = require("../../../assets/images/jira_blog/1.png");
const image3 = require("../../../assets/images/jira_blog/2.png");
const image4 = require("../../../assets/images/freshservice_blog/3_3.png");
const image5 = require("../../../assets/images/jira_blog/3.png");
const image6 = require("../../../assets/images/jira_blog/4.png");
const image7 = require("../../../assets/images/jira_blog/5.png");
const image8 = require("../../../assets/images/jira_blog/6.png");
const image9 = require("../../../assets/images/jira_blog/7.png");
const image10 = require("../../../assets/images/jira_blog/8.png");
const image11 = require("../../../assets/images/jira_blog/9.png");
const image12 = require("../../../assets/images/jira_blog/10.png");
const image13 = require("../../../assets/images/jira_blog/11.png");
const image14 = require("../../../assets/images/jira_blog/12.png");
const image15 = require("../../../assets/images/jira_blog/13.png");
const image16 = require("../../../assets/images/freshservice_blog/l_1.png");
const image17 = require("../../../assets/images/freshservice_blog/l_2.png");

const ldSchema = {
  "@context": "https://schema.org",
  "@type": "BlogPosting",
  mainEntityOfPage: {
    "@type": "WebPage",
    "@id":
      "https://workativ.com/conversational-ai-platform/slack-chatbot-guide",
  },
  headline: "Slack Chatbot Guide for your Business",
  image: "",
  author: {
    "@type": "Organization",
    name: "Team Workativ",
  },
  publisher: {
    "@type": "Organization",
    name: "Workativ",
    logo: {
      "@type": "ImageObject",
      url:
        "https://workativ.com/static/workativ-logo-5eea21f2dcd20bbbbf52debb41c0f26e.png",
    },
  },
  datePublished: "2021-08-05",
  dateModified: "2021-08-05",
};

export default function FeatureHomePage() {
  const isSmall = useMedia({ maxWidth: "520px" });

  return (
    <>
      <TitleAndMetaTags
        title="Free Jira Chatbot Guide for your Business. Build Jira Chatbot for automated workplace support."
        description="Build Jira Chatbot without any coding in minutes Workativ’s platform provides easy tools to quickly build your Jira chatbot and automate workplace support "
        keywords={[
          "Slack Chatbot, Automation, AI Chatbot",
          "workativ free trial",
          "Slack chatbot free trial",
          "Slack Chatbot",
        ]}
        ogImage={image1}
        schema={ldSchema}
      />
      <section className="market_main_wrapper chatbot-guide-blog-main-container">
        <Container>
          <Layout backgroundColor={"_bg_market"} logoFor="ASSISTANT">
            <div className="grid_container_chatbot">
              <div className="container">
                <div className="row">
                  <div className="grid_container_chatbot_wrapper">
                    <div className="grid__wrapper">
                      <div className="col-lg-12 col-md-12 col-sm-12 col-xs-12 trial_header_left">
                        <div className="main-slider-left-market">
                          <h1 className="font-page-header">
                            The Ultimate Guide to Jira Chatbot  
                          </h1>
                        </div>
                      </div>
                      <section className="table_contents_wrapper">
                        <h3 class="font-section-sub-header-small">
                          Table of contents
                        </h3>
                        <div className="table_contents_link font-section-normal-text-medium">
                          <AnchorLink offset={180} href="#section1">
                            1. What is Jira Chatbot?
                          </AnchorLink>
                          <AnchorLink offset={180} href="#section2">
                            2. How does Jira Chatbot works?
                          </AnchorLink>
                          <AnchorLink offset={180} href="#section3">
                            3. What is Jira Chatbot used for?
                          </AnchorLink>
                          <AnchorLink offset={180} href="#section4">
                            4. Best Jira Chatbot platform
                          </AnchorLink>
                          <AnchorLink offset={180} href="#section5">
                            5. Smarter AI-powered Jira automation with Jira
                            Chatbot
                          </AnchorLink>
                          <AnchorLink offset={180} href="#section6">
                            6.Building Jira Chatbot Without coding
                          </AnchorLink>
                          <AnchorLink offset={180} href="#section7">
                            7. Business benefits of Jira chatbot
                          </AnchorLink>
                          <AnchorLink offset={180} href="#section8">
                            8. Conclusion
                          </AnchorLink>
                        </div>
                      </section>

                      {/* Header Section */}
                      <div className="col-lg-12 col-md-12 col-sm-12 col-xs-12 market_wrapper_page line-height-2">
                        <p class="font-section-normal-text line-height-2">
                          You might’ve heard the term “Jira” used by any of the
                          members in your product team at your company
                          irrespective of what role you’re in. To make it clear
                          for you, Jira is actually a tool developed by the
                          Australian Company, Atlassian. It is used for bug
                          tracking, issue tracking, and project management.  
                        </p>
                        <p className="font-section-normal-text line-height-2">
                          The basic use of Jira is to track issues and bugs
                          related to your company’s software and mobile apps.
                          There is also another product from Atlassian called
                          Jira Service Desk which is simply a modern service
                          desk software. It brings more structure to your work,
                          gives you increased visibility into what your team is
                          up to, and helps your agents get more done, so you can
                          deliver better service. So that’s about it when it
                          comes to Jira or Jira Service Desk.
                        </p>
                      </div>

                      <div className="col-lg-12 col-md-12 col-sm-12 col-xs-12 market_wrapper_page line-height-2">
                        {/* Section 1 */}
                        <div id="section1">
                          <h2 className="font-section-sub-header-small-bold">
                            What is Jira Chatbot?
                          </h2>
                          <p class="font-section-normal-text line-height-2">
                            If you own a business or work for one that’s using
                            Jira or Jira Service Desk, then you can automated
                            all of your Jira and Jira Service Desk tasks using
                            Jira chatbots so that your employees/colleagues can
                            get more work done in a jiff
                          </p>
                        </div>

                        {/* Section 2 */}
                        <div
                          className="col-lg-12 col-md-12 col-sm-12 pl-0 col-xs-12 market_wrapper_page"
                          id="section2"
                        >
                          <h3 className="font-section-sub-header-small-bold">
                            How does Jira Chatbot works? 
                          </h3>
                          <p class="font-section-normal-text line-height-2">
                            <a href="https://workativ.com/conversational-ai-platform/jira-chatbot">
                              Jira chatbots
                            </a>{" "}
                            use Natural Language Processing to understand a
                            user’s intention or utterances for initiating a
                            conversation with it as well as to extract required
                            data from a user’s query and pass it to the
                            automation to resolve the user’s issue in
                            real-time. So, think of how intelligent chatbots
                            work, but with the Jira chatbot, it can not only
                            have those benefits, but also extend the
                            functionalities of the Jira tool using advanced
                            integration and workflows. 
                          </p>
                          <p className="font-section-normal-text line-height-2">
                            The Jira Cloud bot allows team members to quickly
                            locate problems, assign them to teammates, modify
                            them, record time against them, watch and unwatch
                            them, and vote/unvote on them.
                          </p>
                          <p className="font-section-normal-text line-height-2">
                            By mentioning the Jira Cloud bot in the channel
                            scope, you can begin the conversation.   You can
                            begin by searching for the work item using keywords
                            or by noting the work item's direct action (for
                            example, log). Selecting the work item you're
                            searching for from the search results will reveal an
                            actionable card with a brief overview and numerous
                            actionable choices like assign, log work, watch, and
                            so on. Work item edits will cause the work item card
                            to refresh in its place. If you change the priority
                            from low to medium, for example, the work item card
                            will be updated to reflect the new priority.
                          </p>
                        </div>

                        {/* Section 3 */}
                        <div
                          className="col-lg-12 col-md-12 col-sm-12 col-xs-12 pl-0 market_wrapper_page"
                          id="section3"
                        >
                          <h3 className="font-section-sub-header-small-bold">
                            What is Jira chatbot used for?
                          </h3>
                          <p class="font-section-normal-text line-height-2">
                            A Jira chatbot can be quite helpful not just for
                            your users but also for the agents as it helps
                            automate user interactions on repetitive tasks and
                            helps your team focus on more important and complex
                            tasks that require human touch.
                          </p>
                          <p class="font-section-normal-text line-height-2">
                            We have compiled a list of most repetitive tasks
                            that you can automate using{" "}
                            <a href="https://workativ.com/conversational-ai-platform/jira-chatbot">
                              Jira chatbot:
                            </a>
                          </p>

                          <p class="font-section-normal-text mb-0 pb-0">
                            1. Jira 
                          </p>
                          <ul
                            class="font-section-normal-text"
                            style="float: left; width: 100%;"
                          >
                            <li>Create an Issue </li>
                            <li> Updating a request </li>
                            <li>Create a comment </li>
                          </ul>
                          <p class="font-section-normal-text mb-0 pb-0">
                            2. Jira Service Desk 
                          </p>
                          <ul
                            class="font-section-normal-text"
                            style="float: left; width: 100%;"
                          >
                            <li>Creating a request </li>
                            <li>Retrieving a request’s details </li>
                            <li>Retrieving approval details </li>
                            <li>Retrieving approval details by ID </li>
                            <li>Creating a Customer </li>
                          </ul>
                        </div>

                        {/* Section 4 */}
                        <div
                          className="col-lg-12 col-md-12 col-sm-12 pl-0 col-xs-12 market_wrapper_page"
                          id="section4"
                        >
                          <h3 className="font-section-sub-header-small-bold">
                            Best Jira Chatbot
                          </h3>
                          <p class="font-section-normal-text line-height-2">
                            Workativ Assistant is an AI-powered no-code platform
                            for building contextual chatbots with automated
                            workflows for business apps. Workativ Assistant’s
                            chatbots can be easily added to you Slack/Microsoft
                            Teams workspace so that your employees can
                            self-serve their IT issues on-the-go, anytime. 
                          </p>
                          <p className="font-section-normal-text line-height-2">
                            By integrating Jira with Workativ Assistant, you
                            ensure your employees get the IT/HR support they’d
                            love. 
                          </p>
                        </div>

                        <NocodeWrapper />

                        {/* Section 5 */}
                        <div
                          className="col-lg-12 col-md-12 col-sm-12 col-xs-12 pl-0 mb-0 market_wrapper_page"
                          id="section5"
                        >
                          <h2 className="font-section-sub-header-small-bold">
                            Smarter AI-powered Jira automations with Jira
                            Chatbot
                          </h2>

                          <p className="font-section-normal-text line-height-2 mb-0 pb-0">
                            1. Jira 
                          </p>
                          <p className="font-section-normal-text line-height-2">
                            <span className="font-section-normal-text-medium">
                              Create an Issue 
                            </span>
                            — No need to log in to your business’ Jira workspace
                            again and again. You can just tell Workativ
                            Assistant’s chatbot to create an issue and it does
                            this for you in an instant. 
                          </p>
                          <p className="font-section-normal-text line-height-2">
                            <span className="font-section-normal-text-medium">
                              Update an Issue
                            </span>
                            — Updating a Jira issue can never be this easier.
                            Open your Slack or Microsoft Teams workspace, have a
                            quick chat with Workativ Assistant’s chatbot there
                            and tell it to update the required fields and you’re
                            done. 
                          </p>
                          <p className="font-section-normal-text line-height-2">
                            <span className="font-section-normal-text-medium">
                              Create a comment
                            </span>
                            — You can even ask Workativ Assistant’s chatbot to
                            create a comment for you in Jira and it does this
                            for you in a flash. 
                          </p>

                          <p className="font-section-normal-text line-height-2 mb-0 pb-0">
                            2. Jira Service Desk 
                          </p>
                          <p className="font-section-normal-text line-height-2">
                            <span className="font-section-normal-text-medium">
                              Creating a request
                            </span>
                            — Creating a Jira Service Desk requests becomes a
                            piece of cake with Workativ Assistant’s chatbots.
                            You’ll just have to tell a Workativ Assistant
                            chatbot to create a request for you and it does this
                            in an instant. 
                          </p>
                          <p className="font-section-normal-text line-height-2">
                            <span className="font-section-normal-text-medium">
                              Retrieving a request’s details
                            </span>
                            — You can check the status of a request by asking
                            Workativ Assistant’s chatbot to retrieve details
                            about a request. 
                          </p>

                          <p className="font-section-normal-text line-height-2">
                            <span className="font-section-normal-text-medium">
                              Retrieving approval details
                            </span>
                            — Converse with Workativ Assistant’s chatbot and it
                            can retrieve approval details for you in a flash. 
                          </p>
                          <p className="font-section-normal-text line-height-2">
                            <span className="font-section-normal-text-medium">
                              Retrieving approval details by ID
                            </span>
                            — Want Workativ Assistant’s chatbot to retrieve
                            approval details by ID? Yes please. 
                          </p>
                          <p className="font-section-normal-text line-height-2">
                            <span className="font-section-normal-text-medium">
                              Creating a Customer
                            </span>
                            — You can ask Workativ Assistant’s chatbot to create
                            a customer in your Jira workspace in case a new
                            employee has joined your organization.   
                          </p>
                          <p className="font-section-normal-text line-height-2">
                            Now that we know what all you can do with Workativ
                            Assistant Jira integration, let’s take a look at how
                            you can create an issue in Jira. 
                          </p>
                        </div>

                        {/* Section 6 */}
                        <div
                          className="col-lg-12 col-md-12 col-sm-12 pl-0 col-xs-12 market_wrapper_page"
                          id="section6"
                        >
                          <h3 className="font-section-sub-header-small-bold">
                            Building Jira Chatbot Without coding
                          </h3>
                          <h3 className="font-section-normal-text-medium">
                            Step 1. Creating a bot workspace  
                          </h3>
                          <p class="font-section-normal-text line-height-2 mb-0 pb-0">
                            When you sign up for Workativ Assistant, you’ll be
                            required to name your workspace. You can provide the
                            name of  your organization as a workspace name. {" "}
                          </p>
                          <img
                            loading="lazy"
                            src={image1}
                            className="mb-5"
                          ></img>
                          <h3 className="font-section-normal-text-medium">
                            Step 2. Download your bot
                          </h3>
                          <p class="font-section-normal-text line-height-2 mb-0 pb-0">
                            Download a prebuilt Jira bot from the bot
                            Marketplace   
                          </p>
                          <img
                            loading="lazy"
                            src={image2}
                            className="mb-4"
                          ></img>
                          <img
                            loading="lazy"
                            src={image3}
                            className="mb-5"
                          ></img>
                          <p className="font-section-normal-text line-height-2">
                            So that’s all there is to the initial set up of your
                            workspace. Next, let’s download Jira workflows
                          </p>
                          <h3 className="font-section-normal-text-medium">
                            Step 3. Setting up a Jira app workflow 
                          </h3>
                          <p class="font-section-normal-text line-height-2 mb-1 pb-0">
                            Lets download Jira app workflow from marketplace   
                          </p>

                          <p class="font-section-normal-text line-height-2 mb-0 pb-0">
                            Click on Download from Marketplace  
                          </p>
                          <img
                            loading="lazy"
                            src={image4}
                            className="mb-5"
                          ></img>

                          <p class="font-section-normal-text line-height-2 mb-0 pb-0">
                            Select the Jira application   
                          </p>
                          <img
                            loading="lazy"
                            src={image5}
                            className="mb-5"
                          ></img>

                          <p class="font-section-normal-text line-height-2 mb-0 pb-0">
                            Select the app workflow  
                          </p>
                          <img
                            loading="lazy"
                            src={image6}
                            className="mb-5"
                          ></img>

                          <p class="font-section-normal-text line-height-2 mb-0 pb-0">
                            Connect with your Jira account    
                          </p>
                          <img
                            loading="lazy"
                            src={image7}
                            className="mb-4"
                          ></img>
                          <img
                            loading="lazy"
                            src={image8}
                            className="mb-4"
                          ></img>
                          <img
                            loading="lazy"
                            src={image9}
                            className="mb-5"
                          ></img>

                          <p class="font-section-normal-text line-height-2 mb-0 pb-0">
                            Test the app workflow   
                          </p>
                          <img
                            loading="lazy"
                            src={image10}
                            className="mb-5"
                          ></img>

                          <h3 className="font-section-normal-text-medium">
                            Step 4: Now add workflow to the Jira chatbot 
                          </h3>
                          <p class="font-section-normal-text line-height-2 mb-0 pb-0">
                            Add app workflow to the dialog conversation  
                          </p>
                          <img
                            loading="lazy"
                            src={image11}
                            className="mb-5"
                          ></img>

                          <p class="font-section-normal-text line-height-2 mb-0 pb-0">
                            Select the workflow  
                          </p>
                          <img
                            loading="lazy"
                            src={image12}
                            className="mb-5"
                          ></img>

                          <p class="font-section-normal-text line-height-2 mb-0 pb-0">
                            Provide the inputs & save it   
                          </p>
                          <img
                            loading="lazy"
                            src={image13}
                            className="mb-4"
                          ></img>
                          <img
                            loading="lazy"
                            src={image14}
                            className="mb-5"
                          ></img>

                          <p class="font-section-normal-text line-height-2 mb-0 pb-0">
                            Test your Jira chatbot    
                          </p>
                          <img
                            loading="lazy"
                            src={image15}
                            className="mb-5"
                          ></img>

                          <h3 className="font-section-normal-text-medium mb-0 pb-0">
                            Step 5: Deploy Jira chatbot in your favourite chat
                            channel   
                          </h3>
                          <img
                            loading="lazy"
                            src={image16}
                            className="mb-4"
                          ></img>
                          <img
                            loading="lazy"
                            src={image17}
                            className="mb-3"
                          ></img>
                        </div>

                        <div className="build_needs">
                          <div class="col-lg-12 col-md-12 col-12 pl-0 center_features_header">
                            <h2 className="font-section-header mb-3">
                              Steps to build Jira Chatbot with workflow
                              automation
                            </h2>
                          </div>
                        </div>

                        <VideoBlog
                          videoURL={
                            "https://www.youtube-nocookie.com/embed/QM3FH8z-9c0?autoplay=1%22"
                          }
                          imgURL={VidImg}
                        />

                        {/* Section 7 */}
                        <div
                          className="col-lg-12 col-md-12 col-sm-12 col-xs-12 pl-0 mb-0 market_wrapper_page"
                          id="section7"
                        >
                          <h2 className="font-section-sub-header-small-bold">
                            Business benefits of Jira chatbot&nbsp; with
                            Workativ Assistant
                          </h2>
                          <h3 className="font-section-normal-text-medium">
                            1. No-code platform to create chatbots that perform
                            Jira tasks in just minutes  
                          </h3>
                          <p class="font-section-normal-text line-height-2">
                            No need to get a developer on board to create a
                            chatbot that takes care of Jira tasks using Workativ
                            Assistant. Workativ Assistant’s Automation Designer
                            enables you to{" "}
                            <a href="https://workativ.com/conversational-ai-platform">
                              create a chatbot in just three simple steps
                            </a>{" "}
                            with just a few clicks. Or you can altogether skip
                            this by going to the Automations Marketplace that
                            houses over 200+{" "}
                            <a href="https://workativ.com/conversational-ai-platform/marketplace">
                              ready-to-use process automations
                            </a>{" "}
                            out-of-the-box and downloading the Jira automation
                            of your choice. And, you can set up any kind of
                            conversation/dialog flow for a chatbot with Workativ
                            Assistant’s Dialog Designer using the plethora of
                            options the Dialog Designer powers you with.  
                          </p>
                          <h3 className="font-section-normal-text-medium">
                            2. Creating a Synergy between your existing Business
                            Apps  
                          </h3>

                          <p className="font-section-normal-text line-height-2">
                            Like you the Active Directory Jira integration
                            worked synergistically, you can combine your other
                            business apps creating an automated workflow that
                            does much more with those apps in just a single
                            run.  
                          </p>

                          <h5 className="font-section-normal-text-medium">
                            3. Faster resolution of repetitive IT issues via
                            on-the-go Self-Service 
                          </h5>
                          <p className="font-section-normal-text line-height-2">
                            Since Workativ Assistant’s chatbots will be added to
                            your business’ Slack or Microsoft Teams workspace,
                            you enable your employees to take care of their IT
                            issues themselves from the comfort of their chat hub
                            on their laptop/mobile phone, on-the-go. They don’t
                            have to log in multiple times into static portals to
                            do the same. 
                          </p>

                          <h5 className="font-section-normal-text-medium">
                            4. 24×7 IT/HR Support   
                          </h5>
                          <p className="font-section-normal-text line-height-2">
                            You can make Workativ Assistant’s chatbots available
                            24×7 for your employees so that they can{" "}
                            <a href="https://workativ.com/conversational-ai-platform/conversational-ai-chatbot">
                              interact with the chatbot to resolve their IT
                              issues in real-time.  
                            </a>
                          </p>

                          <h5 className="font-section-normal-text-medium">
                            5. Knowledgebase at fingertips  
                          </h5>
                          <p className="font-section-normal-text line-height-2">
                            And not to say, you can even set up Workativ
                            Assistant’s chatbot so that it can retrieve certain
                            information about FAQs or documents for your
                            employees that they may need to access
                            occasionally.  
                          </p>
                        </div>

                        {/* Section 8*/}
                        <div
                          className="col-lg-12 col-md-12 col-sm-12 pl-0 col-xs-12 market_wrapper_page"
                          id="section8"
                        >
                          <h3 className="font-section-sub-header-small-bold">
                            Conclusion  
                          </h3>

                          <p class="font-section-normal-text line-height-2">
                            Chatbots have been around for a long time now. They
                            have been used as a way to reduce the workload on
                            customer service representatives and to provide a
                            personal touch when it comes to service. In this
                            blog, we will discuss how Jira can use chatbots to
                            provide an even better understanding of how
                            customers are using the software for better service.
                            Workativ allows you to create an AI bot for your
                            office in minutes with no coding necessary. Workativ
                            is a free, no-code SaaS platform that allows
                            organisations to develop, automate, and deploy
                            conversational AI chatbots in less than one hour.
                            <a href="https://workativ.com/conversational-ai-platform/jira-chatbot">
                              &nbsp;Try our Jira chatbot for FREE now.
                            </a>
                          </p>
                        </div>
                      </div>
                      <ExistingBlogCta
                        ContentCta="Auto-resolve 60% of Your Employee Queries With Generative AI Chatbot & Automation."
                        ButtonText="Book a Demo"
                        isColor="black"
                        backgroundImage={cta_1}
                        mobileBackgroundImage={cta_1_mob}
                      />
                      <div className="most_popular_links">
                        <div className="most_popular_header font-section-sub-header-bold">
                          Related Articles
                        </div>
                        <div className="most_popular_ul">
                          <ul className="section__ul">
                            <li>
                              <a
                                className="blog_usecase_link font-section-normal-text"
                                href="https://workativ.com/conversational-ai-platform/conversational-ai-chatbot-for-employee-service"
                              >
                                Conversational AI Chatbot for employee service
                                automation
                              </a>
                            </li>
                            <li>
                              <a
                                className="blog_usecase_link font-section-normal-text"
                                href="https://workativ.com/conversational-ai-platform/ms-teams-bot-workplace-support-bot"
                              >
                                MS Teams Bot - Your ultimate workplace support
                              </a>
                            </li>
                            <li>
                              <a
                                className="blog_usecase_link font-section-normal-text"
                                href="https://workativ.com/conversational-ai-platform/conversational-ai-chatbot"
                              >
                                What Is Conversational AI? A Guide to
                                Conversational AI Chatbots
                              </a>
                            </li>
                          </ul>
                        </div>
                      </div>
                      <div className="button_last">
                        <button>
                          <a href="https://blog.workativ.com/category/conversational-ai">
                            Conversational AI
                          </a>
                        </button>
                      </div>
                    </div>
                    <div className="sticky_right_img">
                      <a href="/conversational-ai-platform/case-studies/goto-implements-chatbot-for-it-helpdesk-automation">
                        {" "}
                        <img src={topImage} alt="goto testimonial cta" />{" "}
                      </a>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            {/* <GoToPopup /> */}
            <RequestForm isFooterForm={true} />
          </Layout>
        </Container>
      </section>
    </>
  );
}

const NocodeWrapper = () => {
  return (
    <div className="nocode_wrapper margin-no-code">
      <h4>No Code - Free Jira Chatbot</h4>
      <button>
        <a
          href="https://assistant.workativ.com/authentication/u/direct-signup"
          alt="jira chatbot"
        >
          Get Started
        </a>
      </button>
    </div>
  );
};
